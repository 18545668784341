import { Service, Inject } from 'typedi';
import axios from 'axios';

import config from './config';

@Service()
export default class Api {
  private readonly BASE_URL = config.API.BASE_URL;
  private readonly URL_PATH: string = `/adid`;

  constructor(
    @Inject('accesskey') public accesskey: string,
    @Inject('secretkey') public secretkey: string
  ) {}
 
  async send<T>(payload: T) {
    await axios.post(`${this.BASE_URL}${this.URL_PATH}`, payload, {
      headers: {
        accesskey: this.accesskey,
        secretkey: this.secretkey,
      }
    });
  }
}