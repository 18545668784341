import 'reflect-metadata';

import { Service, Container } from 'typedi';
import FlesSDK from './sdk';

type Callback = (e: any) => {}
type Event = { eventName: string, cb: Callback }

@Service()
export class FlesAnalysis {
  public readonly flesSDK: FlesSDK;
  private events: Event[] = [];

  constructor (
    accesskey: string,
    secretkey: string
  ) {
    Container.set('accesskey', accesskey);
    Container.set('secretkey', secretkey);

    this.flesSDK = Container.get(FlesSDK); 
  }

  registerClickEvent(eventName: string, cb: Callback): FlesAnalysis{
    this.events.push({
      eventName, cb
    })
    return this;
  }

  async start() {
    await this.flesSDK.init(this.events);
    console.info(`[FlesAnalysis INFO] api accesskey: ${this.flesSDK.api.accesskey}, secretKey: ${this.flesSDK.api.secretkey}`);
    console.info(`[FlesAnalysis COMPLETE] start Finger Printing : ${this.flesSDK.visitorId}`);
  }

  async track<T>(payload: T) {
    await this.flesSDK.api.send<T>(payload);
  }
 }

 