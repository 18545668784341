import { Service, Inject } from 'typedi';

import FingerPrint from "./fingerprint";
import Api from "./api";
import Tracking from './tracking';

type Callback = (e: any) => {}
type Event = { eventName: string, cb: Callback }

@Service()
export default class FlesSDK {
  visitorId: string;

  constructor(
    @Inject() public fingerPrint: FingerPrint,
    @Inject() public api: Api,
    @Inject() public tracking: Tracking,
  ) { }

  async init(events: Event[]) {
    this.visitorId = await this.fingerPrint.setFingerPrint();
    // await this.tracking.init(events);
    this.tracking.registerEvent(events);
  }
}
