import { Service } from 'typedi';
import * as _ from 'lodash';

const T = 350;

type Callback = (e: any) => {}
type Event = { eventName: string, cb: Callback }

@Service()
export default class Tracking {
  mousePositionX: number;
  mousePositionY: number;

  constructor() { }

  registerEvent(events: Event[]) {
    events.forEach((event: Event) => {
      if (event.eventName === 'mousemove' || event.eventName === 'scroll') {
        document.addEventListener(event.eventName, _.debounce(event.cb, T));
      } else {
        document.addEventListener(event.eventName, event.cb);
      }
    })
  }
}