import { Service } from 'typedi';
import FingerprintJS from '@fingerprintjs/fingerprintjs'

@Service()
export default class FingerPrint {
  fpPromise: any;
  visitorId: string;

  constructor() {
    this.fpPromise = FingerprintJS.load();
  }

  async setFingerPrint(): Promise<string> {
    const fp = await this.fpPromise;
    const result = await fp.get();
  
    this.visitorId = result.visitorId;

    return this.visitorId;
  }
}